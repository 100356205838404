import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMapMarkerAlt } from "react-icons/fa";
import zipBg from '../../assets/image/zip-form-background.jpg';
import checkZip from '../../utils/checkZip'; 

function ZipCta() {
    const [zipCode, setZipCode] = useState('');
    const navigate = useNavigate(); // Hook for programmatic navigation

    const handleZipChange = (e) => {
        setZipCode(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        await checkZip(zipCode, navigate).catch((error) => {
            console.error('Error in checkZip:', error); // Log any errors from checkZip
        });
    };    

    return (
    <>
        <div className="col-lg-12 mb-3 mt-3">
            <div className="position-relative rounded-3 overflow-hidden p-4"
                style={{
                    backgroundImage: `url(${zipBg})`, 
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}>
                <div className="image-overlay-2"></div>
                    <div className="position-relative w-100 d-flex flex-column justify-content-center align-items-start text-white py-4 px-1"
                        style={{
                            zIndex: 2
                        }}
                    >
                    <h6 className="font-2 text-white">Provide Zip Code to,</h6>
                    <h3 className="font-1 lh-1 fw-bold fs-1 mb-3 text-white">View local services:</h3>
                    <form onSubmit={handleSubmit} className="d-flex flex-column h-100 justify-content-center w-100 needs-validation mb-3 form" noValidate="">
                        <div className="d-flex align-items-center mb-3">
                            <div className="flex-grow-1 me-3 col-lg-3">
                                <input 
                                    type="text" 
                                    className="form-control py-3 px-4 lh-3 p-5" 
                                    style={{
                                        fontSize: '1.2rem',
                                        fontWeight: '600',
                                        border: '3px solid #000',
                                        borderRadius: '0.5rem'
                                    }}
                                    name="zip" 
                                    id="zip" 
                                    placeholder="Zip/Postal:"
                                    onChange={handleZipChange} 
                                />
                            </div>
                            <div className="col-lg-7">
                                <button 
                                    type="submit" 
                                    className="btn btn-dark py-3 px-4 lh-3 fw-bold text-white"
                                    style={{
                                        fontSize: '1.20rem',
                                        fontWeight: '600',
                                        borderRadius: '0.5rem'
                                    }}
                                >
                                    View Local Services
                                    <FaMapMarkerAlt
                                        style={{
                                            fontSize: '1.5rem',
                                            marginLeft: '0.5rem'
                                        }}
                                    />
                                    </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
    );
}

export default ZipCta;