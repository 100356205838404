import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSectionCorp from '../../components/Services/SingleServiceSectionCorp';
import HeaderCorporate from '../../components/Header/HeaderCorporate';
import servicesData from '../../data/services.json';
import Footer from '../../components/Footer/Footer';

function PersonalCareCorp({ cityData, locationInfo }) {
  const [inpersonalcareData, setPersonalcareData] = useState({});

  useEffect(() => {
    // Load homemaking data from JSON and update image path
    const updatedData = {
      ...servicesData.personalCare,
      image: require('../../assets/image/service-companionship.png') // Use require for image
    };
    setPersonalcareData(updatedData);
  }, []);

  return (
    <>
      <HeaderCorporate />
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle="Personal Care"
      />
      <SingleServiceSectionCorp
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={inpersonalcareData.title}
        serviceImage={inpersonalcareData.image}
        serviceDescription={inpersonalcareData.description}
        serviceSecondaryDescription={inpersonalcareData.secondaryDescription}
        stats={inpersonalcareData.stats}
        serviceWeProvide={inpersonalcareData.serviceWeProvide}
        servicePoints={inpersonalcareData.servicePoints}
        faqs={inpersonalcareData.faqs}
      />
      <Footer 
        cityData={cityData}
        locationInfo="default"
      />
    </>
  );
}

export default PersonalCareCorp;