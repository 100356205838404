import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import checkZip from '../../utils/checkZip'; 
import heroImage from '../../assets/image/hero.jpg';
import { FaMapMarkerAlt } from 'react-icons/fa';

function HeroCorporate() {
    const [zipCode, setZipCode] = useState('');
    const navigate = useNavigate(); // Hook for programmatic navigation

    const handleZipChange = (e) => {
        setZipCode(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        await checkZip(zipCode, navigate).catch((error) => {
            console.error('Error in checkZip:', error); // Log any errors from checkZip
        });
    };   

    return (
    <>
        <section 
            className="section image-infinite-bg position-relative"
            style={{
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                padding: '15em 1em 15em 1em'
            }}
        >
            <div className="r-container h-100">
                <div className="image-overlay"></div>
                <div 
                    className="d-flex flex-column justify-content-center gap-3 h-100 position-relative"
                    style={{
                        zIndex: 2,
                        maxWidth: '768px'
                    }}
                >
                    <h1 className="text-title text-white fw-bold font-1 lh-1">
                        Senior Care,<br />
                        Made Simple
                    </h1>
                    <p className="text-white f-18 text-font">
                        GentiCare is your care-first, senior home health care provider.
                    </p>
                    <div className="col-lg-12 mb-3 bg-accent-color rounded-4">
                        <div className="rounded-3 overflow-hidden p-4">
                                <div className="w-100 d-flex flex-column justify-content-center align-items-start text-white py-4 px-1"
                                    style={{
                                        zIndex: 2
                                    }}
                                >
                                <h6 className="font-2 text-white">Provide Zip Code to,</h6>
                                <h3 className="font-1 lh-1 fw-bold fs-1 mb-3 text-white">View local services in your neighborhood:</h3>
                                <form onSubmit={handleSubmit} className="d-flex flex-column h-100 justify-content-center w-100 needs-validation mb-3 form" noValidate="">
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="flex-grow-1 me-3 col-lg-4">
                                            <input 
                                                type="text" 
                                                className="form-control py-3 px-4 p-5" 
                                                style={{
                                                    fontSize: '1.45rem',
                                                    fontWeight: '600',
                                                    border: '3px solid #000',
                                                    borderRadius: '0.5rem'
                                                }}
                                                name="zip" 
                                                id="zip" 
                                                placeholder="Zip/Postal:" 
                                                onChange={handleZipChange}
                                            />
                                        </div>
                                        <div className="col-lg-8">
                                            <button 
                                                type="submit" 
                                                className="btn btn-dark py-4 px-4 lh-1 fw-bold text-white"
                                                style={{
                                                    fontSize: '1.45rem',
                                                    fontWeight: '600',
                                                    borderRadius: '0.5rem'
                                                }}
                                            >
                                                View Local Services
                                                <FaMapMarkerAlt
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        marginLeft: '0.5rem'
                                                    }}
                                                />
                                                </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}

export default HeroCorporate;