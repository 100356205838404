import React from 'react';
import HeroSlim from '../components/Hero/HeroSlim';
import CtaForm from '../components/CTA/CtaForm';
import CtaWide from '../components/CTA/CtaWide';

function ResourcesCity({ cityData, locationInfo }) {
  return (
    <>
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle="Resources"
      />
      <CtaWide
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <CtaForm
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </>
  );
}

export default ResourcesCity;