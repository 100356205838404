import React from 'react';
import { MdLocalPhone } from "react-icons/md";
import zipBg from '../../assets/image/zip-form-background.jpg';

function PhoneSideCta({ cityData, locationInfo }) {

    return (
    <>
        <div className="col-lg-12 mb-3 mt-3">
            <div className="position-relative rounded-3 overflow-hidden p-3"
                style={{
                    backgroundImage: `url(${zipBg})`, 
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}>
                <div className="image-overlay-2"></div>
                    <div className="position-relative w-100 d-flex flex-column justify-content-center align-items-start text-white py-4 px-1"
                        style={{
                            zIndex: 2
                        }}
                    >
                    <h6 className="font-2 text-white">Ready to speak to a local Care Professional?</h6>
                    <h3 className="font-1 lh-1 fw-bold fs-1 mb-3 mt-3 text-white">
                        <MdLocalPhone /> Call now: {locationInfo.tracking_phone}
                    </h3>
                </div>
            </div>
        </div>
    </>
    );
}

export default PhoneSideCta;