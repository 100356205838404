import React, { useState } from 'react';
import SingleServiceSidebar from './SingleServiceSidebar';
import serviceImage3 from '../../assets/image/service-companionship.png';

function SingleServiceSection({
  cityData,
  locationInfo,
  serviceTitle,
  serviceImage,
  serviceDescription = '', // Default to empty string if undefined
  serviceSecondaryDescription,
  stats = [],
  serviceWeProvide = '', // Default to empty string if undefined
  servicePoints = [],
  faqs = []
}) {
  const [openFaqIndex, setOpenFaqIndex] = useState(0); // Set initial FAQ to be open

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  return (
    <>
      <section className="section">
        <div className="r-container">
          <div className="d-flex flex-lg-row flex-column-reverse gap-3">
            <SingleServiceSidebar locationInfo={locationInfo} />
            <div className="col col-lg-9">
              <div className="d-flex flex-column gap-3">
                {/* Service Title and Description */}
                <div className="d-flex flex-column mb-3">
                  <h3 className="font-1 fw-bold">{serviceTitle}</h3>
                  <img 
                    src={serviceImage} 
                    alt={serviceTitle} 
                    className="img-fluid" 
                    style={{
                        marginBottom: '1rem'
                    }}
                />
                  {serviceDescription.split("\n\n").map((paragraph, index) => (
                    <p 
                        key={index}
                        style={{
                            marginBottom: '1rem'
                        }}
                    >{paragraph}</p>
                  ))}

                  {/* Stats Section */}
                  <div className="row row-cols-1 row-cols-lg-3">
                    {stats.map((stat, index) => (
                      <div className="col" key={index}>
                        <div className="card with-border-bottom border-0 shadow d-flex flex-column justify-content-center align-items-center p-2">
                          <h3 className="accent-color font-1 fw-bold lh-1">{stat.value}</h3>
                          <h5 className="font-1 fw-bold">{stat.label}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Service We Provide */}
                <div className="d-flex flex-column mb-3">
                  <h3 className="font-1 fw-bold">Service We Provide</h3>
                  {serviceWeProvide.split("\n\n").map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}
                </div>

                {/* Service Points */}
                <div className="d-flex flex-lg-row flex-column mb-3">
                  <div className="col">
                    <div className="position-relative">
                      <img src={serviceImage3} alt="" className="img-fluid" />
                      <div
                          className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center">
                          <button type="button" className="btn request-loader" data-bs-toggle="modal"
                              data-bs-target="#videomodal">
                              <i className="fa-solid fa-play"></i>
                          </button>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column ps-3">
                      {servicePoints.map((point, index) => (
                        <div 
                            className="d-flex flex-row gap-2 align-items-center" 
                            key={index}
                            style={{
                                marginBottom: '1rem',
                                fontSize: '1.2rem',
                                fontWeight: 'bold'
                            }}
                        >
                          <i className="fa-solid fa-square-check accent-color"></i>
                          {point}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* FAQs Section */}
                <div className="d-flex flex-column">
                  <h3 className="font-1 fw-bold">Frequently Asked Questions</h3>
                  <div className="accordion d-flex flex-column gap-2" id="accordionExample">
                    {faqs.map((faq, index) => (
                      <div className="accordion-item" key={index}
                        style={{
                            paddingBottom: '1.25rem',
                        }}
                      >
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button fw-bold ${openFaqIndex === index ? '' : 'collapsed'}`}
                            type="button"
                            onClick={() => toggleFaq(index)}
                            aria-expanded={openFaqIndex === index}
                            aria-controls={`collapse${index}`}
                            style={{
                                padding: '1rem',
                                borderRadius: '0.5rem',
                                fontSize: '1.2rem!important',
                                letterSpacing: '0.05rem',
                            }}
                          >
                            {faq.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${openFaqIndex === index ? 'show' : ''}`}
                          data-bs-parent="#accordionExample"
                          style={{
                            padding: '1rem',
                            backgroundColor: '#f9f9f9',
                            letterSpacing: '0.05rem'
                          }}
                        >
                          <div className="accordion-body"
                            style={{
                                fontSize: '1.2rem'
                            }}
                          >{faq.answer}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SingleServiceSection;
