import cityConfig from '../cityConfig'; // Adjust the path as needed
import { database } from '../firebaseConfig'; // Adjust the path as needed

const checkZip = async (zipCode, navigate) => {
    const genticareRef = database.ref('zip/genticare');
    const zipQuery = genticareRef.orderByChild('zip_map').equalTo(zipCode.toString());
    
    const snapshot = await zipQuery.once('value');
    if (snapshot.exists()) {
        let found = false; // Flag to check if a matching location has been found
        snapshot.forEach((childSnapshot) => {
            if (found) return; // If found, no need to check further
            const locationId = parseInt(childSnapshot.val().location_id); // Ensure location_id is treated as an integer
            const cityKey = Object.keys(cityConfig).find(key => cityConfig[key].locationId === locationId);
            if (cityKey) {
                const cityUrl = cityConfig[cityKey].url;
                console.log('Redirecting to:', cityUrl); // Debugging log
                navigate(`/${cityUrl}/services`);
                found = true; // Set the flag to true as matching location is found
            }
        });
        if (!found) console.log('No matching city found in cityConfig');
    } else {
        console.log('No matching location found in Firebase for zip:', zipCode);
        navigate(`/services?ref=no-location-found`);
    }
};

export default checkZip;
