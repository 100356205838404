import React from 'react';
import HeroSlim from '../components/Hero/HeroSlim';
import ServiceSection from '../components/Services/ServiceSection';
import ReviewSectionCity from '../components/Reviews/ReviewSectionCity';
import CtaForm from '../components/CTA/CtaForm';
import CtaWide from '../components/CTA/CtaWide';


function QuoteCity({ cityData, locationInfo }) {
  return (
    <>
      <ReviewSectionCity
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <CtaForm
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </>
  );
}

export default QuoteCity;