import React from 'react';
import heroImage from '../../assets/image/hero-slim.jpg';


function HeroSlim({ cityData, locationInfo, heroTitle }) {

    return (
    <>
        <section 
            className="section position-relative hero-slim" 
            style={{
                backgroundImage: `url(${heroImage})`
            }}
        >
            <div className="r-container">
                <div className="image-overlay"></div>
                <div 
                    className="position-relative" 
                    style={{
                        zIndex: 2
                    }}
                >
                    <h1 className="font-1 fw-bold text-white">{heroTitle}</h1>
                    {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item fw-bold"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item fw-bold active" aria-current="page">{heroTitle}</li>
                        </ol>
                    </nav> */}
                </div>
            </div>
        </section>   
    </>
    );
}

export default HeroSlim;