import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import CurrentCityContext from '../../CurrentCityContext';
import background from '../../assets/image/testimonial1.jpg';
import { FiChevronsRight } from "react-icons/fi";

function CtaWide({ cityData, locationInfo }) {
    const { currentCity } = useContext(CurrentCityContext);

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    return (
    <>
        <section 
            className="py-5 position-relative"
            style={{
                backgroundImage: `url(${background})`,
            }} 
        >
            <div className="r-container p-4">
                <div className="image-overlay-2"></div>
                <div 
                    className="position-relative" 
                    style={{
                        zIndex: 2
                    }}
                >
                    <div className="row row-cols-1 row-cols-lg-2 text-lg-start text-center px-lg-0 px-3">
                        <div className="col">
                            <h4 className="text-white font-1 fw-bold fs-1">
                                Looking for a quote?
                            </h4>
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-lg-end justify-content-center h-100 align-items-center">
                                <Link
                                    to={createCityLink("/quote")}
                                    type="button" 
                                    className="btn btn-dark px-4 py-3 fs-5 fw-semibold"
                                >
                                    Get Your Free Quote 
                                    <FiChevronsRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}

export default CtaWide;