import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSectionCorp from '../../components/Services/SingleServiceSectionCorp';
import HeaderCorporate from '../../components/Header/HeaderCorporate';
import servicesData from '../../data/services.json';
import Footer from '../../components/Footer/Footer';

function MemoryCareCorp({ cityData, locationInfo }) {
  const [inmemorycareData, setMemorycareData] = useState({});

  useEffect(() => {
    // Load homemaking data from JSON and update image path
    const updatedData = {
      ...servicesData.memoryCare,
      image: require('../../assets/image/service-companionship.png') // Use require for image
    };
    setMemorycareData(updatedData);
  }, []);

  return (

    <>
      <HeaderCorporate />
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle="Memory Care"
      />
      <SingleServiceSectionCorp
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={inmemorycareData.title}
        serviceImage={inmemorycareData.image}
        serviceDescription={inmemorycareData.description}
        serviceSecondaryDescription={inmemorycareData.secondaryDescription}
        stats={inmemorycareData.stats}
        serviceWeProvide={inmemorycareData.serviceWeProvide}
        servicePoints={inmemorycareData.servicePoints}
        faqs={inmemorycareData.faqs}
      />
      <Footer 
        cityData={cityData}
        locationInfo="default"
      />
    </>
  );
}

export default MemoryCareCorp;