import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CurrentCityContext from '../../CurrentCityContext';
import checkZip from '../../utils/checkZip'; 
import serviceImage1 from '../../assets/image/service-companionship.png';
import serviceImage2 from '../../assets/image/service-meal-prep.png';
import serviceImage3 from '../../assets/image/service-homemaking.png';
import serviceImage4 from '../../assets/image/service-personal-care.png';
import serviceImage5 from '../../assets/image/service.jpg';
import { TiHeart } from "react-icons/ti";
import { FaMapMarkerAlt } from "react-icons/fa";
import zipBg from '../../assets/image/zip-form-background.jpg';


function ServiceSection({ cityData, locationInfo }) {
    const { currentCity } = useContext(CurrentCityContext);
    const [zipCode, setZipCode] = useState('');
    const navigate = useNavigate(); // Hook for programmatic navigation

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    const handleZipChange = (e) => {
        setZipCode(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        await checkZip(zipCode, navigate).catch((error) => {
            console.error('Error in checkZip:', error); // Log any errors from checkZip
        });
    };  

    return (
    <>
        <section className="section bg-light">
            <div className="r-container pt-5 pb-5">
                <div className="d-flex flex-column gap-3">
                    <div className="row row-cols-1 row-cols-lg-2 text-lg-start text-center">
                        <div className="col col-lg-12">
                            <div className="d-flex flex-column gap-3 justify-content-center text-center">
                                <h6 className="accent-color font-2 ">
                                    <TiHeart /> 
                                    Caring Services
                                </h6>
                                <h3 className="text-black font-1 lh-1 fw-semibold">Providing Quality Senior Care</h3>
                                <p 
                                    className="text-gray mx-auto" 
                                    style={{
                                        maxWidth: '768px'
                                    }}
                                >
                                    From delivering specialized home care to managing everyday tasks, 
                                    or simply keeping your loved one company, 
                                    we'll work with you to develop a custom home care plan that compassionately responds to your loved one's needs, 
                                    for as long as you need us.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-3 text-center">
                        <div className="col mb-3">
                            <div className="card p-3 with-border-bottom border-0 shadow rounded-3">
                                <Link 
                                    to={createCityLink("/services/in-home-care")}
                                >
                                    <img src={serviceImage1} className="card-img-top rounded-3" alt="..." />
                                </Link>
                                <div className="card-body ">
                                    <Link 
                                            className="text-decoration-none text-dark" 
                                            to={createCityLink("/services/in-home-care")}
                                        >
                                        <h5 className="font-1 fw-bold">In-Home Care</h5>
                                    </Link>
                                    <p className="list-p">
                                    To prevent the feeling of isolation, we happily provide In-Home Care to help seniors remain engaged with their hobbies, 
                                    interests, family, friends, and their local.    
                                    </p>
                                    <div className="d-flex justify-content-center">
                                        <Link 
                                            className="btn post-button font-2 d-flex flex-row align-items-center gap-2" 
                                            to={createCityLink("/services/in-home-care")}
                                        >
                                            Learn more <i className="fa-solid fa-circle-arrow-right mt-1"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="card p-3 with-border-bottom border-0 shadow rounded-3">
                                <Link 
                                    to={createCityLink("/services/companion-care")}
                                >
                                    <img src={serviceImage2} className="card-img-top rounded-3" alt="..." />
                                </Link>
                                <div className="card-body ">
                                    <Link 
                                            className="text-decoration-none text-dark" 
                                            to={createCityLink("/services/companion-care")}
                                        >
                                        <h5 className="font-1 fw-bold">Companion Care</h5>
                                    </Link>
                                    <p className="list-p">
                                    A nutritious diet comes with both health benefits and obvious stimuli. 
                                    Our caregivers are eager to prepare delicious meals. Ethnic comfort meals can be requested as well.
                                    </p>
                                    <div className="d-flex justify-content-center">
                                        <Link 
                                            className="btn post-button font-2 d-flex flex-row align-items-center gap-2" 
                                            to={createCityLink("/services/companion-care")}
                                        >
                                            Learn more <i className="fa-solid fa-circle-arrow-right mt-1"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="card p-3 with-border-bottom border-0 shadow rounded-3">
                                <Link 
                                    to={createCityLink("/services/homemaking")}
                                >
                                    <img src={serviceImage3} className="card-img-top rounded-3" alt="..." />
                                </Link>
                                <div className="card-body ">
                                    <Link 
                                        className="text-decoration-none text-dark" 
                                        to={createCityLink("/services/homemaking")}
                                    >
                                        <h5 className="font-1 fw-bold">Homemaking</h5>
                                    </Link>
                                    <p className="list-p">
                                    We are happy to do light housekeeping services to help our clients with daily activities. 
                                    This enables seniors to stay at home in a safe and clean environment.
                                    </p>
                                    <div className="d-flex justify-content-center">
                                        <Link 
                                            className="btn post-button font-2 d-flex flex-row align-items-center gap-2" 
                                            to={createCityLink("/services/homemaking")}
                                        >
                                            Learn more <i className="fa-solid fa-circle-arrow-right mt-1"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="card p-3 with-border-bottom border-0 shadow rounded-3">
                                <Link 
                                    to={createCityLink("/services/private-duty-care")}
                                >
                                    <img src={serviceImage4} className="card-img-top rounded-3" alt="..." />
                                </Link>
                                <div className="card-body ">
                                    <Link 
                                        className="text-decoration-none text-dark" 
                                        to={createCityLink("/services/private-duty-care")}
                                    >
                                        <h5 className="font-1 fw-bold">Personal Care</h5>
                                    </Link>
                                    <p className="list-p">
                                    Bathing, toileting, dressing and grooming are just some of the daily tasks we can assist with when 
                                    providing daily support to improve independence.
                                    </p>
                                    <div className="d-flex justify-content-center">
                                        <Link 
                                            className="btn post-button font-2 d-flex flex-row align-items-center gap-2" 
                                            to={createCityLink("/services/private-duty-care")}
                                        >
                                            Learn more <i className="fa-solid fa-circle-arrow-right mt-1"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="card p-3 with-border-bottom border-0 shadow rounded-3">
                                <Link 
                                    to={createCityLink("/services/home-nursing")}
                                >
                                    <img src={serviceImage5} className="card-img-top rounded-3" alt="..." />
                                </Link>
                                <div className="card-body ">
                                    <Link 
                                        className="text-decoration-none text-dark" 
                                        to={createCityLink("/services/home-nursing")}
                                    >
                                        <h5 className="font-1 fw-bold">Home Nursing Care</h5>
                                    </Link>
                                    <p className="list-p">
                                    Our Registered Nurses are available to increase peace-of-mind while having your loved one remain in the comforts of their home.

                                    </p>
                                    <div className="d-flex justify-content-center">
                                        <Link 
                                            className="btn post-button font-2 d-flex flex-row align-items-center gap-2" 
                                            to={createCityLink("/services/home-nursing")}
                                        >
                                            Learn more <i className="fa-solid fa-circle-arrow-right mt-1"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="pt-5 pb-5 card p-3 with-border-bottom border-0 shadow rounded-3 position-relative overflow-hidden"
                                style={{
                                    backgroundImage: `url(${zipBg})`, 
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                            >
                                <div className="image-overlay-2"></div>
                                    <div className="card-body position-relative text-white">
                                        <h6 className="font-2 text-white mb-3">Provide Zip Code to,</h6>
                                        <h3 className="font-1 lh-3 fw-bold fs-1 mb-3 text-white mt-3">View local services in your neighborhood:</h3>
                                        <form onSubmit={handleSubmit} className="d-flex flex-column h-100 justify-content-center w-100 needs-validation mb-3 form" noValidate="">
                                            <div className="align-items-center mb-3 mt-3">
                                                <div className="flex-grow-1 me-3 col-lg-12">
                                                    <input 
                                                        type="text" 
                                                        className="form-control py-3 px-4 lh-3 p-5" 
                                                        style={{
                                                            fontSize: '1.2rem',
                                                            fontWeight: '600',
                                                            border: '3px solid #000',
                                                            borderRadius: '0.5rem'
                                                        }}
                                                        name="zip" 
                                                        id="zip" 
                                                        placeholder="Zip/Postal:"
                                                        onChange={handleZipChange} 
                                                    />
                                                </div>
                                                <div className="col-lg-12 mt-4">
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-dark py-3 px-4 lh-1 fw-bold text-white"
                                                        style={{
                                                            fontSize: '1.45rem',
                                                            borderRadius: '0.5rem'
                                                        }}
                                                    >
                                                        View Local Services 
                                                        <FaMapMarkerAlt
                                                            style={{
                                                                fontSize: '1.5rem',
                                                                marginLeft: '0.5rem'
                                                            }}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}

export default ServiceSection;