import React from 'react';
import PhoneSideCta from '../CTA/PhoneSideCta';
import heroImage from '../../assets/image/welcome.jpg';
import { FaMapMarkerAlt } from "react-icons/fa";

function WelcomeCity({ locationInfo }) {

    return (
    <>
        <section className="section mt-5">
            <div className="r-container pt-5 pb-5">
                <div className="d-flex flex-lg-row flex-column-reverse">
                    <div className="col mb-3">
                        <div className="d-flex flex-column gap-3 p-3 h-100 justify-content-center">
                            <h6 className="accent-color font-2 ">
                                <FaMapMarkerAlt
                                    style={{
                                        fontSize: '1.5rem',
                                        marginTop: '-1.25rem'
                                    }}
                                /> 
                                GentiCare {locationInfo.location_name} and surrounding areas</h6>
                            <h3 className="text-black font-1 lh-2 fw-bold">
                                Quality care for your loved ones {'in '+locationInfo.location_name+' and nearby'}
                            </h3>
                            <div className="d-flex flex-lg-row flex-column">
                                <div className="col col-lg-12">
                                    <div className="d-flex flex-column ps-1 mt-2">
                                        <p className="lh-2">
                                        We provide personalized companionship that reduces loneliness, 
                                        offers gentle care to ensure safety, 
                                        and encourages a lifestyle that promotes physical and mental well-being, 
                                        ensuring your loved ones can enjoy a fulfilling and independent life.
                                        </p>
                                        <div className="d-flex flex-column mt-3 gap-2">
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <p className="list-p">
                                                    <i 
                                                        className="fa-solid fa-square-check accent-color"
                                                        style={{
                                                            marginRight: '0.5rem'
                                                        }}
                                                    ></i>
                                                    Serving {locationInfo.location_name} and surrounding areas
                                                </p>
                                            </div>
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                            <p className="list-p">
                                                    <i 
                                                        className="fa-solid fa-square-check accent-color"
                                                        style={{
                                                            marginRight: '0.5rem'
                                                        }}
                                                    ></i>
                                                    Attentive and caring caregivers
                                                </p>
                                            </div>
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <p className="list-p">
                                                    <i 
                                                        className="fa-solid fa-square-check accent-color"
                                                        style={{
                                                            marginRight: '0.5rem'
                                                        }}
                                                    ></i>
                                                    Peace of mind for you and your loved ones
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <PhoneSideCta locationInfo={locationInfo} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col mb-5 pe-lg-3 position-relative">
                        <div className="overlay left"></div>
                        <div className="position-relative ps-5">
                            <img 
                                src={heroImage} 
                                alt="" 
                                className="img-fluid rounded-3" 
                                style={{
                                    zIndex: '-1'
                                }}
                            />
                            {/* <div className="position-absolute bottom-0 end-0 d-flex justify-content-end">
                                <div 
                                    className=" bg-accent-color rounded-3 p-4 w-75" 
                                    style={{
                                        marginBottom: '3rem'
                                    }}
                                >
                                    <h6 className="font-1 text-white fw-bold">The Patient Is First Priority In Our Passion
                                    </h6>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>   
    </>
    );
}

export default WelcomeCity;