import React from 'react';
import HeroCity from '../components/Hero/HeroCity';
import WelcomeCity from '../components/Welcome/WelcomeCity';
import ServiceSection from '../components/Services/ServiceSection';
import ReviewSectionCity from '../components/Reviews/ReviewSectionCity';
import CtaForm from '../components/CTA/CtaForm';
import CtaWide from '../components/CTA/CtaWide';

function City({ cityData, locationInfo }) {
  return (
    <>
      <HeroCity
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <WelcomeCity
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <ServiceSection
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <ReviewSectionCity
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <CtaForm
        cityData={cityData}
        locationInfo={locationInfo}
      />
      <CtaWide
        cityData={cityData}
        locationInfo={locationInfo}
      />
    </>
  );
}

export default City;