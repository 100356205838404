import React, { useContext, useEffect, useState } from 'react';
import { useParams, Routes, Route, Navigate } from 'react-router-dom';
import CurrentCityContext from './CurrentCityContext'; // Adjust the path as necessary
import cityConfig from './cityConfig'; // Adjust the path as necessary
import City from './pages/City';
import Header from './components/Header/Header';
import ServicesCity from './pages/ServicesCity';
import ResourcesCity from './pages/ResourcesCity';
import QuoteCity from './pages/QuoteCity';
import Companionship from './pages/Services/Companionship';
import InHomeCare from './pages/Services/InHomeCare';
import Homemaking from './pages/Services/Homemaking';
import PersonalCare from './pages/Services/PersonalCare';
import MemoryCare from './pages/Services/MemoryCare';
import HomeNursingCare from './pages/Services/HomeNursingCare';
import ReviewsCity from './pages/ReviewsCity';
import RatesCity from './pages/RatesCity';
import Footer from './components/Footer/Footer';
import { database } from './firebaseConfig';

function CityLandingPage() {
    const { city } = useParams();
    const { setCurrentCity } = useContext(CurrentCityContext); // Accessing the context updater
    const cityData = cityConfig[city];
    const [locationInfo, setLocationInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        setCurrentCity(city);

        if (cityData && cityData.locationId) {
            setIsLoading(true); // Start loading
            const locationRef = database.ref(`locations/${cityData.locationId}`);
            locationRef.once('value', (snapshot) => {
                const data = snapshot.val();
                setLocationInfo(data);
                setIsLoading(false); // Stop loading after data is fetched
            });
        } else {
            setIsLoading(false); // Stop loading if cityData is not available
        }
    }, [city, cityData, setCurrentCity]);

    if (!cityData) {
        return <Navigate to="/" />;
    }

    if (isLoading) {
        return ;
    }

    return (
        <div>
            <Header locationInfo={locationInfo} />
            <Routes>
                <Route 
                    path="/" 
                    element={<City city={city} 
                    cityData={cityData} 
                    locationInfo={locationInfo} 
                />} 
                />
                <Route 
                    path="services" 
                    element={<ServicesCity city={city} 
                    cityData={cityData} 
                    locationInfo={locationInfo} 
                />} 
                />
                <Route 
                    path="resources" 
                    element={<ResourcesCity city={city} 
                    cityData={cityData} 
                    locationInfo={locationInfo} 
                    />} 
                />
                <Route 
                    path="reviews" 
                    element={<ReviewsCity city={city} 
                    cityData={cityData} 
                    locationInfo={locationInfo} 
                    />} 
                />
                <Route 
                    path="quote" 
                    element={<QuoteCity city={city} 
                    cityData={cityData}
                    locationInfo={locationInfo} 
                />}
                />
                <Route 
                    path="rates" 
                    element={<RatesCity city={city} 
                    cityData={cityData}
                    locationInfo={locationInfo} 
                />}
                />
                <Route 
                    path="services/companion-care" 
                    element={<Companionship city={city} 
                    cityData={cityData}
                    locationInfo={locationInfo} 
                    />}
                />
                <Route 
                    path="services/memory-care" 
                    element={<MemoryCare city={city} 
                    cityData={cityData}
                    locationInfo={locationInfo} 
                    />}
                />
                <Route 
                    path="services/in-home-care" 
                    element={<InHomeCare city={city} 
                    cityData={cityData}
                    locationInfo={locationInfo} 
                    />}
                />
                <Route 
                    path="services/homemaking" 
                    element={<Homemaking city={city} 
                    cityData={cityData}
                    locationInfo={locationInfo} 
                    />}
                />
                <Route 
                    path="services/private-duty-care" 
                    element={<PersonalCare city={city} 
                    cityData={cityData}
                    locationInfo={locationInfo} 
                    />}
                />
                <Route 
                    path="services/home-nursing" 
                    element={<HomeNursingCare city={city} 
                    cityData={cityData}
                    locationInfo={locationInfo} 
                    />}
                />
            </Routes>
            <Footer cityData={cityData} locationInfo={locationInfo} />
        </div>
    );
}

export default CityLandingPage;