import React from 'react';
import { Link, NavLink } from 'react-router-dom'; 
import headerLogo from '../../assets/image/logo.png';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FiChevronsRight } from "react-icons/fi";

function HeaderCorporate({ cityData, locationInfo }) {


    return (
    <>
        <section className="bg-accent-color-1 py-3">
        <div className="r-container">
            <div className="d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center gap-3">
                <ul className="d-flex m-0 flex-lg-row flex-column justify-content-center align-items-center gap-3 text-white font-2 text-center text-lg-start"
                    style={{
                        listStyle: 'none'
                    }}
                    >
                    <li
                        style={{
                            fontSize: '.95rem',
                            marginLeft: '-20px'
                        }} 
                    > 
                        Senior care, made simple.
                    </li>
                </ul>
                <div className="social-container justify-content-center">
                    <ul className="d-flex m-0 flex-lg-row flex-column justify-content-center align-items-center gap-3 text-white font-2 text-center text-lg-start"
                        style={{
                            listStyle: 'none'
                        }}
                        >
                        <li
                            style={{
                                fontSize: '1.2rem'
                            }} 
                        >
                            <Link 
                                className="text-white fw-semibold" 
                                to='/locations'
                            >
                            <FaMapMarkerAlt 
                                style={{
                                    marginLeft: '0.5rem',
                                    fontSize: '1.35rem',
                                    marginRight: '0.25rem'
                                }} 
                            />
                                Find Local Office
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section className="sticky-top bg-white">
        <div className="r-container">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    {/* <a className="navbar-brand" href="#"> */}
                        <div className="logo-container">
                        <Link to="/">
                            <img src={headerLogo} width="210px" alt="GentiCare Logo" />
                        </Link>
                        </div>
                    {/* </a> */}
                    <button className="navbar-toggler accent-color border-0" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        Menu <i className="fa-solid fa-bars-staggered"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 font-2 fw-semibold gap-lg-3">
                            <li className="nav-item">
                                <NavLink to="/" className="nav-link" end activeclasscame="active">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/services" activeclasscame="active" className="nav-link">Services</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink to="/locations" activeclasscame="active" className="nav-link">Locations</NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink to="/reviews" activeclasscame="active" className="nav-link">Reviews</NavLink>
                            </li> */}
                            <li className="nav-item">
                                <NavLink to="/quote" activeclasscame="active" className="nav-link">Contact</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="https://www.chizebrands.com/franchising?ref=GentiCare" activeclasscame="active" className="nav-link" target="_blank">Franchising</NavLink>
                            </li>
                        </ul>
                            <Link to="/quote" className="btn button bg-accent-color fw-semibold">
                                Get Free Care Quote
                            <FiChevronsRight />
                            </Link>
                    </div>
                </div>
            </nav>
        </div>
    </section>
    </>
    );
}

export default HeaderCorporate;