import React, { useState, useEffect } from 'react';
import { FiChevronsRight } from "react-icons/fi";
import { database } from '../../firebaseConfig';

function CtaForm({ cityData, locationInfo }) {
    const [zip, setZip] = useState('');
    const [ip, setIp] = useState('');

    useEffect(() => {
        // Fetch the user's IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIp(data.ip || ''))
            .catch(error => {
                console.error('Error fetching IP address:', error);
                setIp('');  // Set as blank if fetching fails
            });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const name = e.target.name.value;
        const email = e.target.email.value;
        const phone = e.target.phone.value;
        const message = e.target.message.value;
    
        // Format date and time in ISO format
        const now = new Date();
        const timestamp = now.toISOString(); // ISO string format: "2024-10-31T10:45:23.000Z"
    
        const leadData = {
            lead_name: name,
            lead_email: email,
            lead_phone: phone,
            lead_notes: message,
            ip,
            lead_status: 'new',      // Status of the lead
            timestamp,               // ISO date and time
            brand_id: '2',           // Static brand_id for GentiCare
            is_client: 'no',
            lead_consult_date: 'Not set',
            lead_consult_time: 'Not set',
            lead_primary_type: 'Not set',
        };
    
        if (cityData && cityData.locationId) {
            leadData.location_id = cityData.locationId;
        } else {
            leadData.zip = zip;  // Include zip if no locationId
        }
    
        try {
            await database.ref('consults').push(leadData);
            alert('Your request has been submitted successfully!');
        } catch (error) {
            console.error('Error submitting lead:', error);
            alert('There was an error submitting your request. Please try again.');
        }
    };    

    return (
        <>
            <section className="section bg-light mt-5">
                <div className="r-container p-5">
                    <div className="row row-cols-1 row-cols-lg-2">
                        <div className="col mb-3">
                            <div className="d-flex flex-column gap-3 h-100 justify-content-center">
                                <h6 className="font-2 accent-color">Talk to someone who cares, today!</h6>
                                <h3 className="font-1 lh-1 fw-bold fs-1 mb-3">Why Speak to a Care Expert?</h3>
                                <div className="d-flex flex-column gap-5">
                                    <div className="d-flex flex-row gap-3 align-items-center">
                                        <div className="bg-accent-color rounded-3 py-2 px-4 text-white">
                                            <h4 className="lh-1 m-0 p-0 fw-bold font-1">1</h4>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <h5 className="font-1 fw-bold lh-1 m-0">
                                                Receive a Personalized Care Plan
                                            </h5>
                                            <p className="text-gray m-0">
                                            You will receive a tailored Care Plan to
                                            we ensure your loved ones receive personalized attention and care.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row gap-3 align-items-center">
                                        <div className="bg-accent-color rounded-3 py-2 px-4 text-white">
                                            <h4 className="lh-1 m-0 p-0 fw-bold font-1">2</h4>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <h5 className="font-1 fw-bold lh-1 m-0">
                                                Receive Multiple Quotes
                                            </h5>
                                            <p className="text-gray m-0">
                                            We will provide you with three (3) different quotes 
                                            to cater to each budget and need.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row gap-3 align-items-center">
                                        <div className="bg-accent-color rounded-3 py-2 px-4 text-white">
                                            <h4 className="lh-1 m-0 p-0 fw-bold font-1">3</h4>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <h5 className="font-1 fw-bold lh-1 m-0">
                                                Learn Why We're Different
                                            </h5>
                                            <p className="text-gray m-0">
                                            Complete the form speak with a Care Expert to learn more about 
                                            GentiCare and our care-first approach.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-3">
                            <div className="bg-accent-color rounded-4">
                                <div className="h-100 d-flex flex-column p-5">
                                    <h6 className="font-2 text-white">Complete the form to,</h6>
                                    <h3 className="font-1 lh-1 fw-bold fs-1 mb-3 text-white">Request a callback from a Care Expert:</h3>
                                    <form onSubmit={handleSubmit} className="d-flex flex-column h-100 justify-content-center w-100 needs-validation mb-3 form" noValidate>
                                        <div className="mb-3">
                                            <input 
                                                type="text" 
                                                className="form-control py-3 px-4 lh-3 p-5" 
                                                style={{
                                                    fontSize: '1.2rem',
                                                    fontWeight: '600',
                                                    border: '3px solid #000',
                                                    borderRadius: '0.5rem'
                                                }}
                                                name="name" 
                                                id="name" 
                                                placeholder="Your Name" 
                                                autoComplete='name'
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input 
                                                type="email" 
                                                className="form-control py-3 px-4 lh-3 p-5" 
                                                style={{
                                                    fontSize: '1.2rem',
                                                    fontWeight: '600',
                                                    border: '3px solid #000',
                                                    borderRadius: '0.5rem'
                                                }}
                                                name="email" 
                                                id="email" 
                                                placeholder="Your Email" 
                                                autoComplete='email'
                                                required 
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <input 
                                                type="text" 
                                                className="form-control py-3 px-4 lh-3 p-5" 
                                                style={{
                                                    fontSize: '1.2rem',
                                                    fontWeight: '600',
                                                    border: '3px solid #000',
                                                    borderRadius: '0.5rem'
                                                }}
                                                name="phone" 
                                                id="phone" 
                                                placeholder="Your Phone Number" 
                                                required 
                                            />
                                        </div>
                                        {!cityData?.locationId && (
                                            <div className="mb-3">
                                                <input 
                                                    type="text" 
                                                    className="form-control py-3 px-4 lh-3 p-5" 
                                                    style={{
                                                        fontSize: '1.2rem',
                                                        fontWeight: '600',
                                                        border: '3px solid #000',
                                                        borderRadius: '0.5rem'
                                                    }}
                                                    name="zip" 
                                                    id="zip" 
                                                    placeholder="Your Zip/Postal Code" 
                                                    value={zip}
                                                    onChange={(e) => setZip(e.target.value)}
                                                    required 
                                                />
                                            </div>
                                        )}
                                        <div className="mb-3">
                                            <textarea 
                                                className="form-control py-3 px-4 lh-3 p-5" 
                                                style={{
                                                    fontSize: '1.2rem',
                                                    fontWeight: '600',
                                                    border: '3px solid #000',
                                                    borderRadius: '0.5rem'
                                                }}
                                                id="message" 
                                                name="message" 
                                                rows="3" 
                                                placeholder="Describe the care request..."
                                            ></textarea>
                                        </div>
                                        <div className="mb-3">
                                            <button 
                                                type="submit" 
                                                className="btn btn-dark py-3 fw-semibold fs-5 w-100"
                                            >
                                                Get Free Quote <FiChevronsRight />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CtaForm;
