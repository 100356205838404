import React, { useState, useEffect } from 'react';
import { useLocation, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/css/vendor/fontawesome.css';
import './assets/css/vendor/brands.css';
import './assets/css/vendor/regular.css';
import './assets/css/vendor/solid.css';
import './assets/css/vendor/swiper-bundle.min.css';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/style.css'; 
import Home from './pages/Home';
import CityLandingPage from './CityLandingPage';
import CurrentCityContext from './CurrentCityContext';
import cityConfig from './cityConfig';
import ServicesCorp from './pages/ServicesCorp';
import LocationsCorp from './pages/LocationsCorp';
import QuoteCorp from './pages/QuoteCorp';
import ReviewsCorp from './pages/ReviewsCorp';
import ResourcesCorp from './pages/ResourcesCorp';
import Companionship from './pages/Services/Companionship';
import InHomeCare from './pages/Services/InHomeCare';
import PersonalCare from './pages/Services/PersonalCare';
import Homemaking from './pages/Services/Homemaking';
import HomeNursingCare from './pages/Services/HomeNursingCare';
import MemoryCare from './pages/Services/MemoryCare';
import MemoryCareCorp from './pages/Services/MemoryCareCorp';
import CompanionshipCorp from './pages/Services/CompanionshipCorp';
import InHomeCareCorp from './pages/Services/InHomeCareCorp';
import PersonalCareCorp from './pages/Services/PersonalCareCorp';
import HomemakingCorp from './pages/Services/HomemakingCorp';
import HomeNursingCareCorp from './pages/Services/HomeNursingCareCorp';

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [currentCity, setCurrentCity] = useState(null);

  return (
    <CurrentCityContext.Provider value={{ currentCity, setCurrentCity, cityConfig }}>
      <Router>
        <ScrollToTop />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:city/*" element={<CityLandingPage />} />
            <Route path="/services" element={<ServicesCorp />} />
            <Route path="/locations" element={<LocationsCorp />} />
            <Route path="/reviews" element={<ReviewsCorp />} />
            <Route path="/resources" element={<ResourcesCorp />} />
            <Route path="/quote" element={<QuoteCorp />} />
            <Route path="/services/in-home-care" element={<InHomeCare />} />
            <Route path="/services/companion-care" element={<Companionship />} />
            <Route path="/services/homemaking" element={<Homemaking />} />
            <Route path="/services/private-duty-care" element={<PersonalCare />} />
            <Route path="/services/home-nursing" element={<HomeNursingCare />} />
            <Route path="/services/memory-care" element={<MemoryCare />} />
            <Route path="/care-services/in-home-care" element={<InHomeCareCorp />} />
            <Route path="/care-services/companion-care" element={<CompanionshipCorp />} />
            <Route path="/care-services/homemaking" element={<HomemakingCorp />} />
            <Route path="/care-services/memory-care" element={<MemoryCareCorp />} />
            <Route path="/care-services/private-duty-care" element={<PersonalCareCorp />} />
            <Route path="/care-services/home-nursing" element={<HomeNursingCareCorp />} />
          </Routes>
        </div>
      </Router>
    </CurrentCityContext.Provider>
  );
}

export default App;