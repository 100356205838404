import React from 'react';
import HeaderCorporate from '../components/Header/HeaderCorporate';
import Footer from '../components/Footer/Footer';
import HeroCorporate from '../components/Hero/HeroCorporate';
import WelcomeCorporate from '../components/Welcome/WelcomeCorporate';
import ServiceSection from '../components/Services/ServiceSection';
import ReviewSectionCorporate from '../components/Reviews/ReviewSectionCorporate';
import CtaForm from '../components/CTA/CtaForm';
import CtaWide from '../components/CTA/CtaWide';

function Home() {
  return (
    <div>
      <HeaderCorporate
        cityData="default"
        locationInfo="default"
      />
      <HeroCorporate
        cityData="default"
        locationInfo="default"
      />
      <WelcomeCorporate
        cityData="default"
        locationInfo="default"
      />
      <ServiceSection
        cityData="default"
        locationInfo="default"
      />
      <ReviewSectionCorporate
        cityData="default"
        locationInfo="default"
      />
      <CtaForm
        cityData="default"
        locationInfo="default"
      />
      <CtaWide
        cityData="default"
        locationInfo="default"
      />
      <Footer
        cityData="default"
        locationInfo="default"
      />
    </div>
  );
}

export default Home;