import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSection from '../../components/Services/SingleServiceSection';
import servicesData from '../../data/services.json';

function Companionship({ cityData, locationInfo }) {
  const [companionshipData, setCompanionshipData] = useState({});

  useEffect(() => {
    // Load companionship data from JSON and update image path
    const updatedData = {
      ...servicesData.companionship,
      image: require('../../assets/image/service-companionship.png') // Use require for image
    };
    setCompanionshipData(updatedData);
  }, []);

  return (
    <>
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle={companionshipData.title}
      />
      <SingleServiceSection
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={companionshipData.title}
        serviceImage={companionshipData.image}
        serviceDescription={companionshipData.description}
        serviceSecondaryDescription={companionshipData.secondaryDescription}
        stats={companionshipData.stats}
        serviceWeProvide={companionshipData.serviceWeProvide}
        servicePoints={companionshipData.servicePoints}
        faqs={companionshipData.faqs}
      />
    </>
  );
}

export default Companionship;
