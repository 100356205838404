import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom'; 
import CurrentCityContext from '../../CurrentCityContext';

import { FiChevronsRight } from "react-icons/fi";

function TestimonialFormCta() {
    const { currentCity } = useContext(CurrentCityContext);

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    return (
    <>
        <div className="col bg-accent-color text-white ">
            <div className="d-flex flex-column gap-3 p-5 h-100 justify-content-center">
                <h6 className="font-2 ">
                   Looking for a home care quote?
                </h6>
                <h3 className="font-1 lh-3 fw-bold fs-1">
                    Complete the form to get a free quote in minutes:
                </h3>
                <p className="fs-6 text-white">
                    Receive your free quote from a local Care Provider. We will contact you to discuss your care needs and provide a quote.
                </p>
                <div>
                    <Link 
                        to={createCityLink("/quote")}
                        type="button" 
                        className="btn-dark btn bg-accent-color-1 p-3 header-cta text-white fw-semibold fs-5 w-100"
                    >
                        Get Your Free Consultation <FiChevronsRight />
                    </Link>
                </div>
            </div>
        </div>
    </>
    );
}

export default TestimonialFormCta;