import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSection from '../../components/Services/SingleServiceSection';
import servicesData from '../../data/services.json';

function PersonalCare({ cityData, locationInfo }) {
  const [personalCareData, setPersonalCareData] = useState({});

  useEffect(() => {
    // Load personal care data from JSON and update image path
    const updatedData = {
      ...servicesData.personalCare,
      image: require('../../assets/image/service-personal-care.png') // Use require for image
    };
    setPersonalCareData(updatedData);
  }, []);

  return (
    <>
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle={personalCareData.title}
      />
      <SingleServiceSection
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={personalCareData.title}
        serviceImage={personalCareData.image}
        serviceDescription={personalCareData.description}
        serviceSecondaryDescription={personalCareData.secondaryDescription}
        stats={personalCareData.stats}
        serviceWeProvide={personalCareData.serviceWeProvide}
        servicePoints={personalCareData.servicePoints}
        faqs={personalCareData.faqs}
      />
    </>
  );
}

export default PersonalCare;
