import React from 'react';
import HeaderCorporate from '../components/Header/HeaderCorporate';
import HeroCorporate from '../components/Hero/HeroCorporate';

function LocationsCorp({ cityData }) {
  return (
    <>
      <HeaderCorporate
        cityData="default"
        locationInfo="default"
      />
      <HeroCorporate
        cityData={cityData}
      />
    </>
  );
}

export default LocationsCorp;