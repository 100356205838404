import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSection from '../../components/Services/SingleServiceSection';
import servicesData from '../../data/services.json';

function MemoryCare({ cityData, locationInfo }) {
  const [memoryCareData, setMemoryCareData] = useState({});

  useEffect(() => {
    // Load memory care data from JSON and update image path
    const updatedData = {
      ...servicesData.memoryCare,
      image: require('../../assets/image/service-personal-care.png') // Use require for image
    };
    setMemoryCareData(updatedData);
  }, []);

  return (
    <>
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle={memoryCareData.title}
      />
      <SingleServiceSection
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={memoryCareData.title}
        serviceImage={memoryCareData.image}
        serviceDescription={memoryCareData.description}
        serviceSecondaryDescription={memoryCareData.secondaryDescription}
        stats={memoryCareData.stats}
        serviceWeProvide={memoryCareData.serviceWeProvide}
        servicePoints={memoryCareData.servicePoints}
        faqs={memoryCareData.faqs}
      />
    </>
  );
}

export default MemoryCare;
