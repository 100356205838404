import React from 'react';
import HeroSlim from '../components/Hero/HeroSlim';
import ServiceSectionCorp from '../components/Services/ServiceSectionCorp';
import CtaForm from '../components/CTA/CtaForm';
import CtaWide from '../components/CTA/CtaWide';
import HeaderCorporate from '../components/Header/HeaderCorporate';
import Footer from '../components/Footer/Footer';

function ServicesCorp({ cityData }) {
  return (
    <>
      <HeaderCorporate
        cityData="default"
        locationInfo="default"
      />
      <HeroSlim
        cityData={cityData}
        heroTitle="Services"
      />
      <ServiceSectionCorp
        cityData={cityData}
      />
      <CtaWide
        cityData={cityData}
      />
      <CtaForm
        cityData={cityData}
      />
      <Footer 
        cityData={cityData}
        locationInfo="default"
      />
    </>
  );
}

export default ServicesCorp;