import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSectionCorp from '../../components/Services/SingleServiceSectionCorp';
import HeaderCorporate from '../../components/Header/HeaderCorporate';
import servicesData from '../../data/services.json';
import Footer from '../../components/Footer/Footer';

function InHomeCareCorp({ cityData, locationInfo }) {
  const [inhomecareData, setInhomecareData] = useState({});

  useEffect(() => {
    // Load homemaking data from JSON and update image path
    const updatedData = {
      ...servicesData.inHomeCare,
      image: require('../../assets/image/service-companionship.png') // Use require for image
    };
    setInhomecareData(updatedData);
  }, []);

  return (
    <>
      <HeaderCorporate />
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle="In-Home Care"
      />
      <SingleServiceSectionCorp
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={inhomecareData.title}
        serviceImage={inhomecareData.image}
        serviceDescription={inhomecareData.description}
        serviceSecondaryDescription={inhomecareData.secondaryDescription}
        stats={inhomecareData.stats}
        serviceWeProvide={inhomecareData.serviceWeProvide}
        servicePoints={inhomecareData.servicePoints}
        faqs={inhomecareData.faqs}
      />
      <Footer 
        cityData={cityData}
        locationInfo="default"
      />
    </>
  );
}

export default InHomeCareCorp;