import React from 'react';
import TestimonialFormCta from '../CTA/TestimonialFormCta';
import testimonialImage1 from '../../assets/image/testimonial1.jpg';

function ReviewSection({ cityData, locationInfo }) {

    return (
    <>
        <section 
            className="position-relative" 
            style={{
                backgroundImage: `url(${testimonialImage1})`,
            }}
        >
            <div className="r-container">
                <div className="image-overlay"></div>
                <div 
                    className="d-flex flex-column justify-content-center align-items-center text-center position-relative gap-3" 
                    style={{
                        zIndex: 2,
                        minHeight: '70vh'
                    }}
                >
                    <h4 
                        className="font-1 text-white fw-bold"
                        style={{
                            fontSize: '4rem'
                        }}>
                            Affordable senior care for your loved ones, from a caregiver you can trust.
                    </h4>
                </div>
            </div>
        </section>

        <section 
            className="section pt-0 position-relative" 
            style={{
                marginTop: '-80px'
            }}
        >
            <div className="r-container">
                <div className="overflow-hidden rounded-4">
                    <div className="row row-cols-1 row-cols-lg-2">
                        <TestimonialFormCta />
                        <div className="col bg-light text-white">
                            <div className="overflow-hidden h-100 d-flex align-items-center">
                                <div className="swiper swiper-initialized swiper-horizontal">
                                    <div 
                                        className="swiper-wrapper" 
                                        style={{
                                            transform: 'translate3d(-588px, 0px, 0px)',
                                            transitionDuration: '0ms'
                                        }}
                                    >
                                        <div 
                                            className="swiper-slide" 
                                            style={{
                                                width: '558px',
                                                marginRight: '30px'
                                            }}
                                        >
                                            
                                        </div>
                                        <div 
                                            className="swiper-slide"
                                            style={{
                                                width: '558px',
                                                marginRight: '30px'
                                            }}
                                        >
                                            <div className="d-flex flex-column gap-3 p-4 text-black">
                                                <ul 
                                                    className="d-flex flex-row gap-2 p-0 m-0 text-warning" 
                                                    style={{
                                                        listStyle: 'none'
                                                    }}
                                                >
                                                    <li><i className="fa-solid fa-star"></i></li>
                                                    <li><i className="fa-solid fa-star"></i></li>
                                                    <li><i className="fa-solid fa-star"></i></li>
                                                    <li><i className="fa-solid fa-star"></i></li>
                                                    <li><i className="fa-solid fa-star"></i></li>
                                                </ul>
                                                <p>Honestly I don't know what we would do without our GentiCare caregiver for mom. 
                                                    We absolutely love her! 
                                                    She even accompanies mom to family outings and learned how to prepare mom's favourite meal.
                                                    Truly appreciated.
                                                </p>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex flex-row gap-3">
                                                        <div className="d-flex flex-column h-100 justify-content-center">
                                                            <span className="text-black">Catrina Faber</span>
                                                            <span className="text-gray">a GentiCare review</span>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="accent-color" 
                                                        style={{
                                                            fontSize: '3rem'
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-quote-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}

export default ReviewSection;