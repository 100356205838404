import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSectionCorp from '../../components/Services/SingleServiceSectionCorp';
import HeaderCorporate from '../../components/Header/HeaderCorporate';
import servicesData from '../../data/services.json';
import Footer from '../../components/Footer/Footer';

function CompanionshipCorp({ cityData, locationInfo }) {
  const [companionshipData, setCompanionshipData] = useState({});

  useEffect(() => {
    // Load companionship data from JSON and update image path
    const updatedData = {
      ...servicesData.companionship,
      image: require('../../assets/image/service-companionship.png') // Use require for image
    };
    setCompanionshipData(updatedData);
  }, []);

  return (
    <>
      <HeaderCorporate />
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle="Companionship Care"
      />
      <SingleServiceSectionCorp
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={companionshipData.title}
        serviceImage={companionshipData.image}
        serviceDescription={companionshipData.description}
        serviceSecondaryDescription={companionshipData.secondaryDescription}
        stats={companionshipData.stats}
        serviceWeProvide={companionshipData.serviceWeProvide}
        servicePoints={companionshipData.servicePoints}
        faqs={companionshipData.faqs}
      />
      <Footer 
        cityData={cityData}
        locationInfo="default"
      />
    </>
  );
}

export default CompanionshipCorp;