import React from 'react';
import heroImage from '../../assets/image/hero.jpg';

function HeroCity({ locationInfo }) {

    return (
    <>
        <section 
            className="section image-infinite-bg position-relative"
            style={{
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                padding: '15em 1em 15em 1em'
            }}
        >
            <div className="r-container h-100">
                <div className="image-overlay"></div>
                <div 
                    className="d-flex flex-column justify-content-center gap-3 h-100 position-relative"
                    style={{
                        zIndex: 2,
                        maxWidth: '768px'
                    }}
                >
                    <h1 className="text-title text-white fw-bold font-1 lh-1">
                        Senior Care,<br />
                        Made Simple.
                    </h1>
                    <p className="text-white text-font">
                        Proudly servicing {locationInfo.location_name} and surrounding areas. 
                    </p>
                    <div className="col-lg-9 mb-3 bg-accent-color rounded-4">
                        <div className="rounded-3 overflow-hidden p-4">
                                <div className="w-100 d-flex flex-column justify-content-center align-items-start text-white py-4 px-1"
                                    style={{
                                        zIndex: 2
                                    }}
                                >
                                <h3 className="font-1 lh-2 fw-bold fs-1 mb-3 text-white">
                                    Request your complimentary, <br /> 
                                    Care Consultation today:
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}

export default HeroCity;