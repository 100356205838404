import React from 'react';
import HeaderCorporate from '../components/Header/HeaderCorporate';
import CtaForm from '../components/CTA/CtaForm';
import ReviewSectionCorporate from '../components/Reviews/ReviewSectionCorporate';
import Footer from '../components/Footer/Footer';

function QuoteCorp({ cityData, locationInfo }) {
  return (
    <>
      <HeaderCorporate
        cityData="default"
        locationInfo="default"
      />
      <ReviewSectionCorporate
        cityData={cityData}
      />
      <CtaForm
        cityData={cityData}
      />
      <Footer 
        cityData={cityData}
        locationInfo="default"
      />
    </>
  );
}

export default QuoteCorp;