import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database'
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDIXocsCBLW7HkA4b71dQ7GLC_s7SrxKrU",
    authDomain: "chizeware.firebaseapp.com",
    databaseURL: "https://chizeware-default-rtdb.firebaseio.com",
    projectId: "chizeware",
    storageBucket: "chizeware.appspot.com",
    messagingSenderId: "183862993853",
    appId: "1:183862993853:web:0f2eff4c66d99550c2e8d4",
    measurementId: "G-1NPG73QZ5T"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const database = firebase.database();
export const firestore = firebase.firestore();