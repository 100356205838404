import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSection from '../../components/Services/SingleServiceSection';
import servicesData from '../../data/services.json';

function InHomeCare({ cityData, locationInfo }) {
  const [inHomeCareData, setInHomeCareData] = useState({});

  useEffect(() => {
    // Load in-home care data from JSON and update image path
    const updatedData = {
      ...servicesData.inHomeCare,
      image: require('../../assets/image/service-companionship.png') // Use require for image
    };
    setInHomeCareData(updatedData);
  }, []);

  return (
    <>
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle={inHomeCareData.title}
      />
      <SingleServiceSection
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={inHomeCareData.title}
        serviceImage={inHomeCareData.image}
        serviceDescription={inHomeCareData.description}
        serviceSecondaryDescription={inHomeCareData.secondaryDescription}
        stats={inHomeCareData.stats}
        serviceWeProvide={inHomeCareData.serviceWeProvide}
        servicePoints={inHomeCareData.servicePoints}
        faqs={inHomeCareData.faqs}
      />
    </>
  );
}

export default InHomeCare;
