import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSection from '../../components/Services/SingleServiceSection';
import servicesData from '../../data/services.json';

function HomeNursingCare({ cityData, locationInfo }) {
  const [homeNursingCareData, setHomeNursingCareData] = useState({});

  useEffect(() => {
    // Load home nursing care data from JSON and update image path
    const updatedData = {
      ...servicesData.homeNursingCare,
      image: require('../../assets/image/service-companionship.png') // Use require for image
    };
    setHomeNursingCareData(updatedData);
  }, []);

  return (
    <>
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle={homeNursingCareData.title}
      />
      <SingleServiceSection
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={homeNursingCareData.title}
        serviceImage={homeNursingCareData.image}
        serviceDescription={homeNursingCareData.description}
        serviceSecondaryDescription={homeNursingCareData.secondaryDescription}
        stats={homeNursingCareData.stats}
        serviceWeProvide={homeNursingCareData.serviceWeProvide}
        servicePoints={homeNursingCareData.servicePoints}
        faqs={homeNursingCareData.faqs}
      />
    </>
  );
}

export default HomeNursingCare;
