import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSectionCorp from '../../components/Services/SingleServiceSectionCorp';
import HeaderCorporate from '../../components/Header/HeaderCorporate';
import servicesData from '../../data/services.json';
import Footer from '../../components/Footer/Footer';

function HomeNursingCareCorp({ cityData, locationInfo }) {
  const [homenursingData, setHomenursingData] = useState({});

  useEffect(() => {
    // Load homemaking data from JSON and update image path
    const updatedData = {
      ...servicesData.homeNursingCare,
      image: require('../../assets/image/service-companionship.png') // Use require for image
    };
    setHomenursingData(updatedData);
  }, []);
  
  return (
    <>
      <HeaderCorporate />
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle="Home Nursing Care"
      />
      <SingleServiceSectionCorp
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={homenursingData.title}
        serviceImage={homenursingData.image}
        serviceDescription={homenursingData.description}
        serviceSecondaryDescription={homenursingData.secondaryDescription}
        stats={homenursingData.stats}
        serviceWeProvide={homenursingData.serviceWeProvide}
        servicePoints={homenursingData.servicePoints}
        faqs={homenursingData.faqs}
      />
      <Footer 
        cityData={cityData}
        locationInfo="default"
      />
    </>
  );
}

export default HomeNursingCareCorp;