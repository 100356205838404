import React from 'react';
// import { Link } from 'react-router-dom';
import footerLogo from '../../assets/image/logo-white.png';

function Footer({ cityData, locationInfo }) {
    return (
    <>
        <footer>
        <section className="px-lg-0 px-4 py-lg-5 py-4 bg-accent-color-1">
            <div className="r-container text-white">
                <div className="row row-cols-1 row-cols-lg-4 py-5">
                    <div className="col col-lg-8 mb-3">
                        <div className="d-flex flex-column h-100 justify-content-center">
                            <div>
                                <img 
                                    src={footerLogo} 
                                    alt="Logo" 
                                    className="img-fluid" 
                                    style={{
                                        paddingBottom: '2rem',
                                        maxWidth: '250px'
                                    }}
                                />
                            </div>
                            <p className="text-white">
                                {(locationInfo.location_name ? 'Proudly providing home care services for '+locationInfo.location_name +' and surrounding areas.' : "Proudly providing in-home care services for your loved ones.")}
                                <br /><br />
                                GentiCare is a leader in home care services. We are proud to provide care for seniors and individuals with disabilities in the comfort of their own homes.
                                <br /><br />
                                Choosing a home care provider is a big decision. We would love the opportunity to meet with you and your loved one to discuss your needs and how we can help.
                            </p>
                        </div>
                    </div>
                    {/* <div className="col col-lg-2 mb-3">
                        <div className="d-flex flex-column">
                            <h5 className="font-1 fw-bold mb-3">Quick Links</h5>
                            <div className="d-flex flex-column gap-2">
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Who Are We</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Services</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Rates</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> FAQ</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Contact</Link>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col col-lg-2 mb-3">
                        <div className="d-flex flex-column">
                            <h5 className="font-1 fw-bold mb-3">Information</h5>
                            <div className="d-flex flex-column gap-2">
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Who Are We</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Services</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Rates</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> FAQ</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Contact</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-2 mb-3">
                        <div className="d-flex flex-column">
                            <h5 className="font-1 fw-bold mb-3">Information</h5>
                            <div className="d-flex flex-column gap-2">
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> About Us</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Services</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Delivery</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> FAQ</Link>
                                <Link to='' className="d-flex flex-row gap-2 align-items-center link-light"><i className="fa-solid fa-chevron-right accent-color"></i> Contact</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div 
                    className="w-100 py-3" 
                    style={{
                        borderTop: '1px solid var(--accent-color-2)'
                    }}
                ></div>
                <div className="text-center p-2">
                    GentiCare&copy; {new Date().getFullYear()} {locationInfo.location_name}
                </div>
            </div>
        </section>
    </footer>
    </>
    );
}

export default Footer;