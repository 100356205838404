import React, { useEffect, useState } from 'react';
import HeroSlim from '../../components/Hero/HeroSlim';
import SingleServiceSection from '../../components/Services/SingleServiceSection';
import servicesData from '../../data/services.json';

function Homemaking({ cityData, locationInfo }) {
  const [homemakingData, setHomemakingData] = useState({});

  useEffect(() => {
    // Load homemaking data from JSON and update image path
    const updatedData = {
      ...servicesData.homemaking,
      image: require('../../assets/image/service-homemaking.png') // Use require for image
    };
    setHomemakingData(updatedData);
  }, []);

  return (
    <>
      <HeroSlim
        cityData={cityData}
        locationInfo={locationInfo}
        heroTitle={homemakingData.title}
      />
      <SingleServiceSection
        cityData={cityData}
        locationInfo={locationInfo}
        serviceTitle={homemakingData.title}
        serviceImage={homemakingData.image}
        serviceDescription={homemakingData.description}
        serviceSecondaryDescription={homemakingData.secondaryDescription}
        stats={homemakingData.stats}
        serviceWeProvide={homemakingData.serviceWeProvide}
        servicePoints={homemakingData.servicePoints}
        faqs={homemakingData.faqs}
      />
    </>
  );
}

export default Homemaking;
